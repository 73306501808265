import FileSaver from 'file-saver'
import { ModelGuardRail } from '../api/models/model_guardrail'
import ExcelJS from 'exceljs'
import { ModelProductDetail, Plan, Price, PriceTier } from '@/api/models/model_product_detail'
import { PriceTierV2 } from '@/pages/addon/AddOnPage'
import { PlanConfig } from '@/pages/customer/CustomerSubscription'
import { ModelSubscription } from '@/api/models/model_subscription'

import { format } from 'date-fns'

export function formatDate(date: Date, formatString: string = 'dd/MM/yyyy'): string {
    return format(date, formatString)
}

export function toPascalCase(str: string): string {
    return str.replace(/\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
}

export function formatNumberUS(data: number): string {
    let formattedPrice = ''
    if (data >= 1_000_000_000) {
        formattedPrice = (data / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'
    } else if (data >= 1_000_000) {
        formattedPrice = (data / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'
    } else if (data >= 1_000) {
        formattedPrice = (data / 1_000).toFixed(1).replace(/\.0$/, '') + 'K'
    } else {
        formattedPrice = data.toString()
    }

    return formattedPrice
}

export function formatPrice(data?: number): string {
    const formattedPrice: string = formatNumber(data)
    return '$ ' + formattedPrice
}

export function formatNumber(number?: number): string {
    // Convert the number to a string

    if (number) {
        const numStr = number.toString()

        // Split the number into integer and decimal parts if a decimal exists
        const [integerPart, decimalPart] = numStr.includes('.') ? numStr.split('.') : [numStr, null]

        // Handle the part before the last 3 digits
        let lastThreeDigits = integerPart.substring(integerPart.length - 3)
        let otherDigits = integerPart.substring(0, integerPart.length - 3)

        // Apply comma to the remaining digits in pairs
        if (otherDigits !== '') {
            lastThreeDigits = ',' + lastThreeDigits
            otherDigits = otherDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }

        // Combine the formatted integer part with the decimal part (if it exists)
        return decimalPart !== null
            ? otherDigits + lastThreeDigits + '.' + decimalPart.substring(0, 2).padEnd(2, '0')
            : otherDigits + lastThreeDigits
    } else {
        return '0'
    }
}

export const validateEffectValue = (value: number, operator: string, effectValue: number): boolean => {
    switch (operator) {
        case 'LESS_THAN_OR_EQUAL_TO':
            return value <= effectValue
        case 'MORE_THAN_OR_EQUAL_TO':
            return value >= effectValue
        case 'LESS_THAN':
            return value < effectValue
        case 'MORE_THAN':
            return value > effectValue
        default:
            return false
    }
}

export const validateDiscountString = (valid: boolean, guardRail: ModelGuardRail): string => {
    const operator = guardRail.operator
    if (!valid && operator === 'LESS_THAN_OR_EQUAL_TO') return 'Discount exceeds the maximum value'
    else if (!valid && operator === 'MORE_THAN_OR_EQUAL_TO') return 'Discount is less than the minimum value'
    else if (!valid && operator === 'LESS_THAN') return 'less than'
    else if (!valid && operator === 'MORE_THAN') return 'more than'
    else return 'invalid'
}

export const getLicenseKey = (tab: string): string => {
    switch (tab) {
        case 'plan':
            return 'M7WtmnXqlzIh62h6aYz80UWwb4Ht1N'
        case 'price':
            return '3DzHoox4HqnuXcpdmjmgxNRGRR0RWP'
        case 'feature':
            return '2tzeM0vIEIITBYCuSStqjhJnhJIhfi'
        case 'feature_mapping':
            return 'HwXBfGhJ7Qq4qikGTTeMcUqkBocl5V'
        default:
            return '2tzeM0vIEIITBYCuSStqjhJnhJIhfi'
    }
}

interface ExportToExcelProps {
    json: any[]
    fileName: string
}

export const exportToExcel = async ({ json, fileName }: ExportToExcelProps) => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('fileName')

    const headers = Object.keys(json[0])

    // Catch Headers
    worksheet.addRow(headers)

    // Color Headers
    headers.forEach((head, index) => {
        console.log(head)
        worksheet.getCell(1, index + 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4169E1' },
        }
        worksheet.getCell(1, index + 1).font = {
            bold: true,
            color: { argb: 'FFFFFF' },
        }
    })

    json.forEach((item) => {
        const row = Object.values(item)
        worksheet.addRow(row)
    })

    const buffer = await workbook.xlsx.writeBuffer()

    const data = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    })
    FileSaver.saveAs(data, fileName + '.xlsx')
}

function transformData(data: any[]): any[][] {
    // Extract keys from the first object
    const keys = Object.keys(data[0])

    // Create a new structure
    const transformedData = keys.map((key) => {
        // Extract values for each key
        const values = data.map((item) => item[key])
        return [key, ...values]
    })

    return transformedData
}

function separateFeatureAndKey(data: any): Map<string, any>[] {
    const feature: any = []

    for (const json of data) {
        const result: { [key: string]: any } = {}
        json.forEach((item: any) => {
            result[item.Feature] = item.Value
        })
        feature.push(result)
    }

    return feature
}

interface ExportMultipleQuotationToExcelProps {
    data: any
    fileName: string
}

export const exportMultipleQuotationToExcel = ({ data, fileName }: ExportMultipleQuotationToExcelProps): void => {
    const jsonData = separateFeatureAndKey(data)
    // Transform the data

    const transformedData = transformData(jsonData)

    // Create a new workbook
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet1')

    // Add the transformed data to the worksheet
    transformedData.forEach((row) => {
        worksheet.addRow(row)
    })

    // Add styling to the first row
    const firstRow = worksheet.getRow(1)
    const secondRow = worksheet.getRow(2)
    const thirdRow = worksheet.getRow(3)
    firstRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '0096FF	' },
        }
    })

    secondRow.eachCell((cell) => {
        cell.font = { bold: true }
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cce9ff' },
        }
    })

    thirdRow.eachCell((cell) => {
        cell.font = { bold: true }
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cce9ff' },
        }
    })

    // Adjust column widths
    worksheet.columns.forEach((column) => {
        let maxLength = 0
        if (column)
            column?.eachCell!({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? cell.value.toString().length : 10
                if (columnLength > maxLength) {
                    maxLength = columnLength
                }
            })
        column.width = maxLength + 2
    })

    workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
            const data = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
            })
            FileSaver.saveAs(data, fileName + '.xlsx')
        })
        .catch((err) => {
            console.error('Error writing Excel file:', err)
        })
}

export function editSlug({ prefix, value }: { prefix: string; value: string }): string {
    value = value.trim()
    value = value.toLowerCase()
    const slug = value.split(' ').join('-')
    return `${prefix}-${slug}`
}

export function transformTiersToV2(tiers: PriceTier[]): PriceTierV2[] {
    const result = []
    if (tiers.length == 0) {
        result.push({
            from: 0,
            to: '&above',
            price: 0,
        })
        return result
    }
    for (let i = 0; i < tiers.length; i++) {
        if (i == 0) {
            result.push({
                from: 0,
                to: tiers[i].up_to,
                price: tiers[i].price,
            })
        } else if (i == tiers.length - 1) {
            result.push({
                from: ((tiers[i - 1].up_to as number) + 1) as number,
                to: '&above',
                price: tiers[i].price,
            })
        } else {
            result.push({
                from: ((tiers[i - 1].up_to as number) + 1) as number,
                to: tiers[i].up_to,
                price: tiers[i].price,
            })
        }
    }

    return result
}

export function convertToPlanConfig(prices: Price[], billingPeriod: string): PlanConfig[] {
    const result: PlanConfig[] = []
    const monthlyPrice = prices?.filter(
        (charge) => charge.billing_period === 'MONTHLY' && charge.billing_model === 'FLAT_FEE'
    )[0]
    const annualPrice = prices?.filter(
        (charge) => charge.billing_period === 'ANNUALLY' && charge.billing_model === 'FLAT_FEE'
    )[0]

    result.push({
        name: 'Base Charge',
        quantity: 1,
        type: 'FLAT',
        billingPeriod: (billingPeriod === 'MONTHLY' ? monthlyPrice : annualPrice)?.billing_period ?? 'MONTHLY',
        isEditable: false,
        price: (billingPeriod === 'MONTHLY' ? monthlyPrice : annualPrice)?.monthly_price ?? 0,
        unit: undefined,
        price_id: (billingPeriod === 'MONTHLY' ? monthlyPrice : annualPrice).id,
    })

    prices?.forEach((charge) => {
        if (charge !== monthlyPrice && charge !== annualPrice) {
            result.push({
                name: charge.name,
                quantity: 0,
                billingPeriod: charge.billing_period,
                isEditable: true,
                type: 'FLAT',
                price: charge.monthly_price,
                unit: 'unit',
                price_id: charge.id,
            })
        }
    })
    return result
}

export function covertAddonPriceConfig(prices: Price[], addonName: string, billingPeriod: string): PlanConfig {
    const monthlyPrice = prices.filter((charge) => charge.billing_period === 'MONTHLY')[0]
    const annualPrice = prices.filter((charge) => charge.billing_period === 'ANNUALLY')[0]
    billingPeriod = billingPeriod.toUpperCase()

    const result: PlanConfig = {
        name: addonName,
        quantity: 0,
        billingPeriod: (billingPeriod === 'MONTHLY' ? monthlyPrice : annualPrice)?.billing_period ?? 'MONTHLY',
        isEditable: true,
        type: (billingPeriod === 'MONTHLY' ? monthlyPrice : annualPrice)?.tier_type ?? 'FLAT',
        tiered_price: (billingPeriod === 'MONTHLY' ? monthlyPrice : annualPrice)?.price_tiers ?? [],
        price: (billingPeriod === 'MONTHLY' ? monthlyPrice : annualPrice)?.monthly_price ?? 0,
        unit: undefined,
        price_id: (billingPeriod === 'MONTHLY' ? monthlyPrice : annualPrice)?.id,
    }

    console.log(result)
    return result
}

export type SuperAddOnConversion = {
    [key: string]: {
        id: string
        name: string
        quantity: number
        monthly?: {
            price: number
            price_tiers?: PriceTier[]
            type: string
            price_id: string
            currency: string
            billing_model: string
            billing_cadence: string
        }
        annual?: {
            price: number
            price_tiers?: PriceTier[]
            type: string
            price_id: string
            currency: string
            billing_model: string
            billing_cadence: string
        }
    }
}

export type SuperPlanConversion = {
    [key: string]: {
        id: string
        name: string
        base_charge?: {
            monthly?: {
                price: number
                price_tiers?: PriceTier[]
                type: string
                price_id: string
                currency: string
                billing_model: string
                billing_cadence: string
            }
            annual?: {
                price: number
                price_tiers?: PriceTier[]
                type: string
                price_id: string
                currency: string
                billing_model: string
                billing_cadence: string
            }
        }
        onetime?: {
            price: number
            price_id: string
            currency: string
            type: string
            billing_model: string
            billing_cadence: string
        }
        usage_based_charge?: {
            [featureId: string]: {
                id: string
                slug: string
                monthly?: {
                    price_tiers?: PriceTier[]
                    price_id?: string
                    price?: number
                    type: string
                    currency: string
                    billing_model: string
                    billing_cadence: string
                }
                annual?: {
                    price_tiers?: PriceTier[]
                    currency: string
                    price_id?: string
                    price?: number
                    type: string
                    billing_model: string
                    billing_cadence: string
                }
                quantity: number
            }
        }
    }
}

function transformAddons(addons: Plan[]): SuperAddOnConversion {
    const result: SuperAddOnConversion = {}
    addons.forEach((addon) => {
        const addonEntry: SuperAddOnConversion[string] = {
            id: addon.id,
            name: addon.name,
            quantity: 0,
            monthly: undefined,
            annual: undefined,
        }

        addon.prices?.forEach((price) => {
            if (price.billing_period === 'MONTHLY') {
                addonEntry.monthly = {
                    price: price.monthly_price,
                    price_tiers: price.price_tiers ?? [],
                    price_id: price.id,
                    currency: price.currency,
                    billing_model: price.billing_model,
                    billing_cadence: price.billing_cadence,
                    type: price.tier_type,
                }
            }

            if (price.billing_period === 'ANNUALLY') {
                addonEntry.annual = {
                    price: price.monthly_price,
                    price_tiers: price.price_tiers ?? [],
                    price_id: price.id,
                    currency: price.currency,
                    billing_model: price.billing_model,
                    billing_cadence: price.billing_cadence,
                    type: price.tier_type,
                }
            }
        })

        result[addon.id] = addonEntry
    })

    return result
}

function transformPlans(plans: Plan[]): SuperPlanConversion {
    const result: SuperPlanConversion = {}

    plans.forEach((plan) => {
        const planEntry: SuperPlanConversion[string] = {
            id: plan.id,
            name: plan.name,
            base_charge: undefined,
            onetime: undefined,
            usage_based_charge: {},
        }

        plan.prices?.forEach((price) => {
            if (price.billing_model === 'FLAT_FEE' && price.billing_cadence === 'RECURRING') {
                if (price.billing_period === 'MONTHLY') {
                    if (!planEntry.base_charge) {
                        planEntry.base_charge = {}
                    }
                    planEntry.base_charge.monthly = {
                        price: price.monthly_price,
                        price_tiers: price.price_tiers ?? [],
                        price_id: price.id,
                        currency: price.currency,
                        billing_model: price.billing_model,
                        billing_cadence: price.billing_cadence,
                        type: price.tier_type,
                    }
                } else if (price.billing_period === 'ANNUALLY') {
                    if (!planEntry.base_charge) {
                        planEntry.base_charge = {}
                    }
                    planEntry.base_charge.annual = {
                        price: price.annual_price,
                        price_id: price.id,
                        price_tiers: price.price_tiers ?? [],
                        type: price.tier_type,
                        currency: price.currency,
                        billing_model: price.billing_model,
                        billing_cadence: price.billing_cadence,
                    }
                }
            } else if (price.billing_model === 'FLAT_FEE' && price.billing_cadence === 'ONETIME') {
                planEntry.onetime = {
                    price: price.monthly_price,
                    price_id: price.id,
                    currency: price.currency,
                    type: 'flatfee',
                    billing_model: price.billing_model,
                    billing_cadence: price.billing_cadence,
                }
            } else if (price.billing_model === 'PER_UNIT') {
                const featureId = price.feature_id
                planEntry.usage_based_charge![featureId] = {
                    id: price.feature_id,
                    slug: price.feature_id,
                    monthly:
                        price.billing_period === 'MONTHLY'
                            ? {
                                  price_tiers: price.price_tiers ?? [],
                                  type: price.tier_type,
                                  price: price.monthly_price,
                                  price_id: price.id,
                                  currency: price.currency,
                                  billing_model: price.billing_model,
                                  billing_cadence: price.billing_cadence,
                              }
                            : undefined,
                    annual:
                        price.billing_period === 'ANNUALLY'
                            ? {
                                  price_tiers: price.price_tiers ?? [],
                                  price: price.annual_price,
                                  price_id: price.id,
                                  type: price.tier_type,
                                  currency: price.currency,
                                  billing_model: price.billing_model,
                                  billing_cadence: price.billing_cadence,
                              }
                            : undefined,
                    quantity: 0,
                }
            }
        })

        result[plan.id] = planEntry
    })

    return result
}

export type SuperProductConversion = {
    id: string
    name: string
    slug: string
    status: string
    plans: SuperPlanConversion
    addons: SuperAddOnConversion
}

export function superTransformProduct(product: ModelProductDetail): SuperProductConversion {
    return {
        id: product.id,
        name: product.name,
        slug: product.slug,
        status: product.status,
        plans: transformPlans(product.plans),
        addons: transformAddons(product.add_ons),
    }
}

export function getCurrencySymbol(currency: string): string {
    switch (currency) {
        case 'USD':
            return '$'
        case 'INR':
            return '$'
        case 'EUR':
            return '€'
        default:
            return currency
    }
}

export type SuperSubscriptionConversion = {
    [key: string]: {
        startDate: Date
        planId: string
        planName: string
        productId: string
        billingPeriod: string
        discount: []
        status: string
        reccuringPrice: string
        addOns: {
            [key: string]: {
                id: string
                quantity: number
            }
        }
        lineItems: {
            [type: string]: {
                id: string
                type: string
                quantity: number
            }
        }
    }
}

export function transformSubscription(subscriptions: ModelSubscription[]): SuperSubscriptionConversion {
    const result: SuperSubscriptionConversion = {}
    subscriptions.forEach((subscription) => {
        console.log(subscription.start_date_time)
        const subscriptionEntry: SuperSubscriptionConversion[string] = {
            startDate: new Date(subscription.start_date_time!),
            planId: subscription.plan?.id ?? '',
            planName: subscription.plan?.name ?? '',
            productId: subscription.plan?.product_id ?? '',
            billingPeriod: subscription.billing_period ?? '',
            status: subscription.status ?? '',
            reccuringPrice: '100',
            addOns: {},
            lineItems: {},
            discount: [],
        }

        subscription.line_items?.forEach((lineItem) => {
            subscriptionEntry.lineItems[lineItem.type!] = {
                id: lineItem.id!,
                type: lineItem.type!,
                quantity: lineItem.quantity!,
            }
        })

        subscription.add_ons?.forEach((addOn) => {
            subscriptionEntry.addOns[addOn.add_on_id!] = {
                id: addOn.add_on_id!,
                quantity: addOn.quantity!,
            }
        })

        subscriptionEntry.discount = subscription.discounts ?? []

        result[subscription.id!] = subscriptionEntry
    })
    return result
}
